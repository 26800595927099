<template>
    <div name="dropdown" class="relative cursor-pointer h-10 mt-1 rounded p-2 text-gray-500" v-bind:class="modeType()">
        <li @click="toggleMenu()" class="dropdown-toggle " v-bind:class="css" v-if="selectedOption.name !== undefined">
          {{ selectedOption.name }}
        </li>

        <li @click="toggleMenu()" class="dropdown-toggle" v-if="selectedOption.name === undefined">
          {{placeholderText}}
        </li>

        <ul class="dropdown-menu bg-white" v-if="showMenu">
            <li v-for="option in options">
                <a href="javascript:void(0)" @click="updateOption(option)">
                    {{ option.name }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name: "dropdown",
  data() {
    return {
      selectedOption: {
        name: ""
      },
      showMenu: false,
      placeholderText: "Please select an item"
    };
  },
  props: {
    options: {
      type: [Array, Object]
    },
    selected: {},
    placeholder: [String],
    mode: String,
    css: String
  },

  mounted() {
    this.selectedOption = this.selected;

    if (this.placeholder) {
      this.placeholderText = this.placeholder;
    }
  },

  methods: {
    updateOption(option) {
      this.selectedOption = option;
      this.showMenu = false;
      this.$emit("updateOption", this.selectedOption);
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    modeType() {
      return this.mode == "form" ? "drop-form" : "";
    }
  }
};
</script>

<style>
.btn-group {
  position: relative;
}

.dropdown-toggle {
  padding: 0px;
  text-transform: none;
  font-size: 14px;
  margin-bottom: 7px;
  border: 0;
  float: none;
  box-shadow: none;
  border-radius: 3px;
}

.dropdown-toggle:after {
   font-family: FontAwesome;
    content: '\f078';
    padding-left: 0.5em;
      vertical-align: middle;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  width: 100%;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 0px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  z-index: 9999;
}

.drop-form {
  border: solid 1px rgba(210, 215, 217, 0.1) !important;
  border-radius: 4px !important;
  padding: 12px !important;
  padding-bottom: 32px !important;
}

.dropdown-menu > li > a {
  padding: 10px 30px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: #515365;
  white-space: nowrap;
  text-decoration: none;
}
.dropdown-menu > li > a:hover {
  background-color: inherit;
}

.dropdown-menu > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
}

.caret {
  display: relative;
  width: 0;
  position: relative;
  top: 16px;
  height: 0;
  margin-left: 7px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  float: right;
}

li {
  list-style: none;
}
</style>
